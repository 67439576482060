export const TranslationsTypes = {
  //#region Overview translations
  'OVERVIEW.TITLE': 'OVERVIEW.TITLE',
  'OVERVIEW.BALANCE_DETAIL.STATUS.DRAFT':
    'OVERVIEW.BALANCE_DETAIL.STATUS.DRAFT',
  'OVERVIEW.BALANCE_DETAIL.STATUS.CREATED':
    'OVERVIEW.BALANCE_DETAIL.STATUS.CREATED',
  'OVERVIEW.BALANCE_DETAIL.STATUS.PENDING':
    'OVERVIEW.BALANCE_DETAIL.STATUS.PENDING',
  'OVERVIEW.BALANCE_DETAIL.STATUS.CLOSED':
    'OVERVIEW.BALANCE_DETAIL.STATUS.CLOSED',
  'OVERVIEW.BALANCE_DETAIL.STATUS.FROZEN':
    'OVERVIEW.BALANCE_DETAIL.STATUS.FROZEN',
  'OVERVIEW.BALANCE_DETAIL.TITLE': 'OVERVIEW.BALANCE_DETAIL.TITLE',
  'OVERVIEW.BALANCE_DETAIL.TOTAL_TITLE': 'OVERVIEW.BALANCE_DETAIL.TOTAL_TITLE',
  'OVERVIEW.BALANCE_DETAIL.TOTAL_AVAILABLE_TITLE':
    'OVERVIEW.BALANCE_DETAIL.TOTAL_AVAILABLE_TITLE',
  'OVERVIEW.BALANCE_DETAIL.LOCKED_TITLE':
    'OVERVIEW.BALANCE_DETAIL.LOCKED_TITLE',
  'OVERVIEW.BALANCE_DETAIL.PROVIDER_DEFAULT_TITLE':
    'OVERVIEW.BALANCE_DETAIL.PROVIDER_DEFAULT_TITLE',
  'OVERVIEW.BALANCE_DETAIL.PROVIDER_ZEROHASH_TITLE':
    'OVERVIEW.BALANCE_DETAIL.PROVIDER_ZEROHASH_TITLE',
  'OVERVIEW.BALANCE_DETAIL.PROVIDER_BBVA_TITLE':
    'OVERVIEW.BALANCE_DETAIL.PROVIDER_BBVA_TITLE',
  //#endregion
  //#region History translations
  'HISTORY.TITLE': 'HISTORY.TITLE',
  'HISTORY.GRID.NO_RESULTS': 'HISTORY.GRID.NO_RESULTS',
  'HISTORY.GRID.ERROR': 'HISTORY.GRID.ERROR',
  'HISTORY.GRID.REFRESH': 'HISTORY.GRID.REFRESH',
  'HISTORY.GRID.COLUMN.TYPE': 'HISTORY.GRID.COLUMN.TYPE',
  'HISTORY.GRID.COLUMN.TYPE.ASSETS_BUY': 'HISTORY.GRID.COLUMN.TYPE.ASSETS_BUY',
  'HISTORY.GRID.COLUMN.TYPE.ASSETS_SELL':
    'HISTORY.GRID.COLUMN.TYPE.ASSETS_SELL',
  'HISTORY.GRID.COLUMN.TYPE.DEPOSIT': 'HISTORY.GRID.COLUMN.TYPE.DEPOSIT',
  'HISTORY.GRID.COLUMN.TYPE.DEPOSIT_STABLE_COIN':
    'HISTORY.GRID.COLUMN.TYPE.DEPOSIT_STABLE_COIN',
  'HISTORY.GRID.COLUMN.TYPE.DISTRIBUTION':
    'HISTORY.GRID.COLUMN.TYPE.DISTRIBUTION',
  'HISTORY.GRID.COLUMN.TYPE.EXTERNAL_PAYMENT':
    'HISTORY.GRID.COLUMN.TYPE.EXTERNAL_PAYMENT',
  'HISTORY.GRID.COLUMN.TYPE.WITHDRAWAL': 'HISTORY.GRID.COLUMN.TYPE.WITHDRAWAL',
  'HISTORY.GRID.COLUMN.TYPE.WITHDRAWAL_STABLE_COIN':
    'HISTORY.GRID.COLUMN.TYPE.WITHDRAWAL_STABLE_COIN',
  'HISTORY.GRID.COLUMN.CREATION_DATE': 'HISTORY.GRID.COLUMN.CREATION_DATE',
  'HISTORY.GRID.COLUMN.LAST_UPDATE': 'HISTORY.GRID.COLUMN.LAST_UPDATE',
  'HISTORY.GRID.COLUMN.STATUS': 'HISTORY.GRID.COLUMN.STATUS',
  'HISTORY.GRID.COLUMN.STATUS.PENDING': 'HISTORY.GRID.COLUMN.STATUS.PENDING',
  'HISTORY.GRID.COLUMN.STATUS.CANCELLED':
    'HISTORY.GRID.COLUMN.STATUS.CANCELLED',
  'HISTORY.GRID.COLUMN.STATUS.SETTLED': 'HISTORY.GRID.COLUMN.STATUS.SETTLED',
  'HISTORY.GRID.COLUMN.STATUS.REVERSED': 'HISTORY.GRID.COLUMN.STATUS.REVERSED',
  'HISTORY.GRID.COLUMN.TRANSACTION_AMOUNT':
    'HISTORY.GRID.COLUMN.TRANSACTION_AMOUNT',
  'HISTORY.GRID.COLUMN.FEE': 'HISTORY.GRID.COLUMN.FEE',
  'HISTORY.GRID.COLUMN.TOTAL': 'HISTORY.GRID.COLUMN.TOTAL',
  //#endregion
} as const;

export type TranslationsKeyType = keyof typeof TranslationsTypes;
export const TranslationsKeys = Object.keys(
  TranslationsTypes,
) as TranslationsKeyType[];

export type TranslationsType = Record<TranslationsKeyType, string>;
